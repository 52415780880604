<style lang="less" scoped>
.about-us {
  width: 100%;
  background: #070911;
  &-first {
    width: 100%;
    position: relative;
    img {
      width: 100%;
    }
    &-container {
      width: 76.7%;
      max-width: 1104px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -44%);
      p:nth-child(1) {
        font-size: 20px;
        font-weight: 400;
        color: rgba(249, 98, 47, 1);
        letter-spacing: 3px;
      }
      p:nth-child(2) {
        font-size: 55px;
        font-weight: 600;
        color: rgba(255, 255, 255, 1);
      }
      p:nth-child(4) {
        font-size: 18px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        letter-spacing: 1px;
        margin-top: 260px;
      }
    }
  }
  &-second {
    width: 100%;
    height: 658px;
    background: #070911;
    position: relative;
    &-container {
      width: 76.7%;
      max-width: 1104px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: space-between;
      &-left {
        width: 49.8%;
        img {
          width: 100%;
        }
      }
      &-right {
        width: 42.2%;
        font-weight: 400;
        color: rgba(222, 230, 255, 1);
        letter-spacing: 4px;
        p {
          margin: 0;
          &:nth-child(1) {
            font-size: 30px;
            line-height: 42px;
          }
          &:nth-child(2) {
            font-size: 10px;
            line-height: 14px;
            margin: 5px 0 28px;
          }
          &:nth-child(3) {
            font-size: 14px;
            line-height: 30px;
            letter-spacing: 1px;
            margin-bottom: 90px;
          }
        }
      }
    }
  }
  &-third {
    width: 100%;
    height: 885px;
    background: #070911;
    position: relative;
    &-container {
      width: 76.7%;
      max-width: 1104px;
      height: 100%;
      margin: 0 auto;
      position: relative;
      overflow: hidden;
      .swiper-slide {
        width: 100%;
        img {
          display: block;
          width: 100%;
        }
        &-explain {
          width: 100%;
          height: 285px;
          background: rgba(15, 17, 24, 1);
          color: rgba(222, 230, 255, 1);
          position: relative;
          &-main {
            width: 77.8%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            p:nth-child(1) {
              font-size: 20px;
              font-weight: 400;
              margin: 0;
            }
            p:nth-child(2) {
              font-size: 14px;
              font-weight: 400;
              line-height: 24px;
            }
            p:nth-child(3) {
              width: 90px;
              height: 30px;
              font-size: 12px;
              font-weight: 400;
              text-align: center;
              line-height: 30px;
              letter-spacing: 3px;
              border-radius: 4px;
              border: 1px solid rgba(222, 230, 255, 1);
              margin-top: 50px;
            }
          }
        }
      }
    }
  }
  &-forth {
    width: 100%;
    height: 680px;
    position: relative;
    margin-top: 136px;
    &-container {
      width: 76.7%;
      height: 100%;
      max-width: 1104px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: space-between;
      &-left,
      &-right {
        width: 48%;
        background: rgba(15, 17, 24, 1);
        img {
          width: 100%;
          cursor: pointer;
        }
        .second-second {
          padding: 50px;
          color: rgba(222, 230, 255, 1);
          p:nth-child(1) {
            font-size: 20px;
            font-weight: 400;
            line-height: 28px;
          }
          p:nth-child(2) {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
          }
          p:nth-child(3) {
            width: 90px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            font-size: 12px;
            line-height: 30px;
            letter-spacing: 3px;
            border-radius: 4px;
            cursor: pointer;
            border: 1px solid rgba(222, 230, 255, 1);
          }
        }
      }
    }
  }
  .swiper-pagination.swiper-pagination-render.swiper-pagination-clickable.swiper-pagination-bullets {
    height: 24px;
    line-height: 24px;
    top: 62%;
  }
  /deep/
    .swiper-pagination.swiper-pagination-render.swiper-pagination-clickable.swiper-pagination-bullets
    > .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    margin: 0 18px;
  }
  /deep/
    .swiper-pagination.swiper-pagination-render.swiper-pagination-clickable.swiper-pagination-bullets
    > .swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 6px;
    height: 6px;
    background: rgba(255, 255, 255, 0);
    border: 2px solid rgba(255, 255, 255, 1);
  }
  .r-button {
    width: 140px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    letter-spacing: 0;
    cursor: pointer;
    background: linear-gradient(
      211deg,
      rgba(248, 40, 108, 1) 0%,
      rgba(249, 98, 47, 1) 100%
    );
    border-radius: 29px;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
  }
}
</style>

<template>
  <div class="about-us">
    <div class="about-us-first">
      <img src="../assets/about-us.png"
           alt="">
      <div class="about-us-first-container">
        <p>
          长三角创意经济合作专业委员会
        </p>
        <p>
          CREATIVE ECONOMY <br />
          COOPERATION COMMITTEE
        </p>
        <div class="r-button"
             @click="toAboutUsDetails">
          {{$t('readMore')}}
        </div>
        <p>
          战略合作： 荷兰罗斯加德工作室 /Normal Studio /Lucion Média /MASSIVart /Playmind /L'Eloi
        </p>
      </div>
    </div>
    <div class="about-us-second">
      <div class="about-us-second-container">
        <div class="about-us-second-container-left">
          <img src="../assets/Bitmap.png"
               alt="">
        </div>
        <div class="about-us-second-container-right">
          <p>{{$t('Q27')}}</p>
          <p>{{$t('Q28')}}</p>
          <p>
            {{$t('Q29')}}
          </p>
          <div class="r-button"
               @click="toAboutUsDetails">
            {{$t('readMore')}}
          </div>
        </div>
      </div>
    </div>
    <div class="about-us-third">
      <div class="about-us-third-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="../assets/about-us-1.png"
                 alt=""
                 @click="linkTo('http://www.cecc.sh.cn/news/html/?413.html', '_blank')">
            <div class="swiper-slide-explain">
              <div class="swiper-slide-explain-main">
                <p>
                  {{$t('Q59')}}
                </p>
                <p>
                  {{$t('Q60')}}
                </p>
                <!-- <p @click="linkTo('http://www.cecc.sh.cn/product/class/?74.html', '_blank')"
                   :style="{width: $Cookies.get('cecc-lang') === 'en' ? '120px' : ''}">
                  {{$t('Q58')}}
                </p> -->
                <p :style="{width: $Cookies.get('cecc-lang') === 'en' ? '120px' : '', cursor: 'pointer'}"
                   @click="linkTo('morePhotos')">
                  {{ $t('morePhotos') }}
                </p>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <img src="../assets/about-us-2.png"
                 alt=""
                 @click="linkTo('http://www.cecc.sh.cn/news/html/?413.html', '_blank')">
            <div class="swiper-slide-explain">
              <div class="swiper-slide-explain-main">
                <p>
                  {{$t('Q61')}}
                </p>
                <p>
                  {{$t('Q62')}}
                </p>
                <!-- <p @click="linkTo('http://www.cecc.sh.cn/product/class/?74.html', '_blank')"
                   :style="{width: $Cookies.get('cecc-lang') === 'en' ? '120px' : ''}">
                  {{$t('Q58')}}
                </p> -->
                <p :style="{width: $Cookies.get('cecc-lang') === 'en' ? '120px' : '', cursor: 'pointer'}"
                   @click="linkTo('morePhotos')">
                  {{ $t('morePhotos') }}
                </p>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <img src="../assets/about-us-3.png"
                 alt=""
                 @click="linkTo('http://www.cecc.sh.cn/news/html/?396.html', '_blank')">
            <div class="swiper-slide-explain">
              <div class="swiper-slide-explain-main">
                <p>
                  {{$t('Q63')}}
                </p>
                <p>
                  {{$t('Q64')}}
                </p>
                <!-- <p @click="linkTo('http://www.cecc.sh.cn/product/class/?74.html', '_blank')"
                   :style="{width: $Cookies.get('cecc-lang') === 'en' ? '120px' : ''}">
                  {{$t('Q58')}}
                </p> -->
                <p :style="{width: $Cookies.get('cecc-lang') === 'en' ? '120px' : '', cursor: 'pointer'}"
                   @click="linkTo('morePhotos')">
                  {{ $t('morePhotos') }}
                </p>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <img src="../assets/about-us-4.png"
                 alt=""
                 @click="linkTo('http://www.cecc.sh.cn/news/html/?368.html', '_blank')">
            <div class="swiper-slide-explain">
              <div class="swiper-slide-explain-main">
                <p>
                  {{$t('Q65')}}
                </p>
                <p>
                  {{$t('Q66')}}
                </p>
                <!-- <p @click="linkTo('http://www.cecc.sh.cn/product/class/?74.html', '_blank')"
                   :style="{width: $Cookies.get('cecc-lang') === 'en' ? '120px' : ''}">
                  {{$t('Q58')}}
                </p> -->
                <p :style="{width: $Cookies.get('cecc-lang') === 'en' ? '120px' : '', cursor: 'pointer'}"
                   @click="linkTo('morePhotos')">
                  {{ $t('morePhotos') }}
                </p>
              </div>
            </div>
          </div>
          <!-- <div class="swiper-slide">
            <img src="../assets/about-us-1.png"
                 alt=""
                 @click="linkTo('http://www.cecc.sh.cn/news/html/?368.html', '_blank')">
            <div class="swiper-slide-explain">
              <div class="swiper-slide-explain-main">
                <p>
                  2015上海世界创意经济峰会召开
                </p>
                <p>
                  2015年首届上海世界创意经济峰会国际论坛在东华大学召开，爱丁堡作为主宾城市，峰会主题为“互联网视域下创意产业发展的范式创新和政策选择”，当年活动规模500人，被评为上海市创意产业协会的优秀活动项目。
                  <p @click="linkTo('http://www.cecc.sh.cn/news/html/?432.html', '_blank')" :style="{width: $Cookies.get('cecc-lang') === 'en' ? '120px' : ''}">
                    {{$t('Q58')}}
                  </p>
              </div>
            </div>
          </div> -->
        </div>
        <div class="swiper-pagination swiper-pagination-render"></div>
      </div>
    </div>
    <div class="about-us-forth">
      <div class="about-us-forth-container">
        <div class="about-us-forth-container-left">
          <div class="first-second">
            <img src="../assets/about-us-pic2.png"
                 alt=""
                 @click="openNewLink('https://mp.weixin.qq.com/s/I0ha77tEFDHYj_m442KaqA')">
          </div>
          <div class="second-second"
               :style="{padding: $Cookies.get('cecc-lang') === 'en' ? '0 50px' : ''}">
            <p @click="$Cookies.get('cecc-lang') === 'en' ? openNewLink('https://mp.weixin.qq.com/s?__biz=MzIyMzM4MzgwMg==&amp;mid=2247487712&amp;idx=2&amp;sn=1f36aa12519cc24dc0f7c849b3572db4&amp;scene=19#wechat_redirect'): openNewLink('https://mp.weixin.qq.com/s/I0ha77tEFDHYj_m442KaqA')">{{$t('Q52')}}</p>
            <p>
              {{$t('Q53')}}
            </p>
            <p @click="linkTo('masterView')"
               :style="{width: $Cookies.get('cecc-lang') === 'en' ? '160px' : ''}">
              {{$t('Q54')}}
            </p>
          </div>
        </div>
        <div class="about-us-forth-container-right">
          <div class="first-second">
            <img src="../assets/about-us-pic3.png"
                 alt=""
                 @click="openNewLink('https://mp.weixin.qq.com/s/x7OqP_0Yf5fEmAvJ9waHzA')">
          </div>
          <div class="second-second"
               :style="{padding: $Cookies.get('cecc-lang') === 'en' ? '0 50px' : ''}">
            <p @click="openNewLink('https://mp.weixin.qq.com/s/x7OqP_0Yf5fEmAvJ9waHzA')">{{$t('Q55')}}</p>
            <p>
              {{$t('Q56')}}
            </p>
            <p @click="linkTo('studyTour')"
               :style="{width: $Cookies.get('cecc-lang') === 'en' ? '160px' : ''}">
              {{$t('Q57')}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <Common-activies></Common-activies>
    <Common-footer></Common-footer>
  </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';
import CommonFooter from '../components/common-footer';
import CommonActivies from '../components/common-activities';

export default {
  name: 'about-us',
  data () {
    return {
    }
  },
  created () {
    if (this.$Cookies.get('cecc-lang') === 'en') this.$router.push({
      name: 'aboutUsEn'
    })
  },
  mounted () {
    new Swiper('.about-us-third-container', {
      autoplay: true,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
    })
  },
  computed: {
  },
  methods: {
    toAboutUsDetails () {
      this.$router.push({
        name: 'aboutUs/details'
      })
    },
    openNewLink (link) {
      window.open(link, '_blank');
    },
    linkTo (url, type) {
      if (type) {
        window.open(url, type);
      } else {
        this.$router.push({
          name: url
        })
      }
    }
  },
  components: {
    CommonFooter,
    CommonActivies
  },
}
</script>
